export default function SecondaryButton(props) {
  return (
    <button
      style={props.style}
      disabled={props.disabled}
      onClick={props.onClick}
      className={"secondary-button " + (props.small ? "small" : "")}
    >
      {props.label}
    </button>
  );
}

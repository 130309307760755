 
import React, { useEffect, useState } from "react";

const FullScreenClock = () => {
  const [date, setDate] = useState( new Date().toLocaleTimeString());
  useEffect(() => {
    var timeInterval = setInterval(() => {
      setDate(new Date().toLocaleTimeString());
    },1000);
    return () => clearInterval(timeInterval);
  },[])
  return (
    <div className="fs-clock">
      {date}
    </div>
  )
}
export default React.memo(FullScreenClock);
import {useEffect, useState} from "react";
import chooseBall from "../../model/choose-ball";
import label from "../../services/Translation/lang";
import ChooseNumbersSvg from "../svg/choose-numbers-svg";
import ChooseNumbersBall from "./ChooseNumbersBall";
import soundEffect from "../../services/Sounds/SoundFxPlayer";
import storage from "../../services/Storage/Storage";

export default function ChooseNumbers(props) {
  /**
   * @Note
   * If there any more label.t returns " - "
   * Use this hot fix.
   */
  const [isLabelLoaded, setIsLabelLoaded] = useState(false);
  useEffect(() => {
    if (isLabelLoaded === false) setTimeout( () => { setIsLabelLoaded(true) }, 300)
  }, [isLabelLoaded])
  return (
   <div style={{ display: props?.tab?.visible }} className="choose-numbers">
    <div className="game-header">
      <div className="d-flex">
        <ChooseNumbersSvg className="live-game-icon" />
        {
          label.t("choose_numbers")
        }
      </div>
      <div className="game-header-text">{label.t("between")} 6-12</div>
    </div>
    <div className="choose-numbers-body">
      {chooseBall.map((ball, index) => {
        return (
          <ChooseNumbersBall
            onClick={() => {
              props.setTicketNumbers((prevState) => {
                var local;
                // can be here generally but better only on deselect
                // storage.set('dontCheckBackToMin', false);
                if (prevState.length + 1 > 12) {
                  // deselect
                  if (prevState.filter((b) => {
                      return b === ball.number ? true : false;
                    }).length === 1) {
                    storage.set('dontCheckBackToMin', false);
                    soundEffect.play('selectBTN_2');
                    local = [...prevState];
                    local.splice([...prevState].indexOf(ball.number), 1);
                    return [...local];
                  } else {
                    return [...prevState];
                  }
                } else if (prevState.indexOf(ball.number) !== -1) {
                  // deselect
                  storage.set('dontCheckBackToMin', false);
                  soundEffect.play('selectBTN_2');
                  local = [...prevState];
                  local.splice(local.indexOf(ball.number), 1);
                  return [...local];
                } else {
                  // select
                  storage.set('dontCheckBackToMin', true);
                  soundEffect.play('selectBTN_1');
                  return [...prevState, ball.number];
                }
              })
            }}
            label={ball.number}
            activeLabel={props.ticketNumbers.filter((b) => {
              return b === ball.number && props.clearChoosenNumbers === false
                ? true
                : false;
            })}
            key={index}
            disabled={
              props.betStatus === 1 ? true : false
            }
          />
        );
      })}
    </div>
  </div>)
}

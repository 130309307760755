import loadingScreenBackground from "../../assets/images/loading-screen-background-low.webp";
import logo from "../../assets/images/logo.png";
import FadeIn from "react-fade-in";
import {useEffect, useState} from "react";

export default function Disconnected(props) {
  const [applicationErrorMsg, setApplicationErrorMsg ] = useState(" ");
  useEffect(()=> {
    setApplicationErrorMsg(props.label.get.disconnected)
  } , [props.label.get.disconnected])
  return (
     <div className={"loading-screen-inapp"}>
      <div className="loading-screen-background" style={{zIndex: -1}}>
        <img
          src={loadingScreenBackground}
          alt="loading-background"
        />
      </div>
      <FadeIn style={{zIndex: 10000000, padding: '12px', textAlign: 'center'}} delay={200}>
        <div style={{fontFamily: '"Kanit-Medium", Roboto-Medium', fontSize: '40px', paddingBottom: '20px', color: '#fff'}}>
          {applicationErrorMsg}
        </div>

        <div style={{paddingBottom: '20px'}}>
        <img
          src={logo}
          alt="loading-background"
        />

        </div>
      </FadeIn>
    </div>
  );
}

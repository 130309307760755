export default function GetBackButton(props) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={"get-back-button " + props.className}
    >
      {props.label}
      <svg
        width="7"
        height="10"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M.293.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L4.586 6 .293 1.707a1 1 0 010-1.414z"
          fill="#fff"
        ></path>
      </svg>
    </button>
  );
}

import { Accordion } from "react-bootstrap";
import label from "../../services/Translation/lang";
import BetRoundsBall from "./BetRoundsBall";
import oddHolder from "../../model/odd-holder";

export default function BetRoundBox(props) {
  return (
    <Accordion.Item eventKey={props.eventKey}>
      <Accordion.Header>
        {label.t("round")} {props.roundId}
        <div className="bet-round-time">
          <span className="me-2">{props.date}</span>
          <span>{props.time}</span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="accordion-body-background"></div>
        {props.balls.map((ball, index) => {
          return (
            <BetRoundsBall
              position={(index + 1)}
              label={ball}
              odd={oddHolder[index].odd}
              key={index}
            />
          );
        })}
      </Accordion.Body>
    </Accordion.Item>
  );
}

import handleNumber from "../../services/Helpers/HandleNumber";

export default function BetRoundsBall(props) {
  return (
    <div className="bet-rounds-ball-holder">
      <div className="bet-rounds-ball-position">{props.position}</div>
      <div className="bet-rounds-ball-wrapper">
        <div className={"bet-rounds-ball " + handleNumber(props.label)}>
          <div className="bet-rounds-ball-border">{props.label}</div>
        </div>
      </div>
      <div className="bet-rounds-ball-odd">{props.odd}</div>
    </div>
  );
}

import React, {useEffect} from "react";
import FadeIn from "react-fade-in";
import oddHolder from "../../model/odd-holder";
import {formatNumber} from "../../services/Helpers/NumberFormatter";
import storage from "../../services/Storage/Storage";
import label from "../../services/Translation/lang";
import GetBackButton from "../Buttons/GetBackButton";
import getUrlParam from '../../services/UrlParams/urlParams';

const menuIcons = {
  sound: "/menu-icons/sound-setting.svg",
  music: "/menu-icons/music-settings.svg",
  history: "/menu-icons/promo-history.svg",
  provablyFair: "/menu-icons/provably-fair.svg",
  gameRules: "/menu-icons/game-rules.svg",
  betHistory: "/menu-icons/bet-history.svg",
  gameRulesAll: "/game-rules/gameroles.png",
  gameRules1: "/game-rules/gamerules-1.png",
  gameRules2: "/game-rules/gamerules-2.png",
  gameRules2xe: "/game-rules/gamerules-2-xe.png",
  gameRules2fc: "/game-rules/gamerules-2-fc.png",
  gameRules2gc: "/game-rules/gamerules-2-gc.png",
  gameRules2sc: "/game-rules/gamerules-2-sc.png",
  gameRules3: "/game-rules/gamerules-3.png",
  gameRules3xe: "/game-rules/gamerules-3-xe.png",
  gameRules3fc: "/game-rules/gamerules-3-fc.png",
  gameRules3gc: "/game-rules/gamerules-3-gc.png",
  gameRules3sc: "/game-rules/gamerules-3-sc.png",
  gameRules4: "/game-rules/gamerules-4.png",
};

var paramLang = getUrlParam('lang') || getUrlParam('language');
var paramCurrency = getUrlParam('currency');
if (paramCurrency !== null) paramCurrency = paramCurrency.toLowerCase()

export default function GameRules(props) {
  useEffect(() => {
    if(document.getElementById('AUTOPLAY_MAX')) {
      var t = document.getElementById('AUTOPLAY_MAX');
      t.innerText = sessionStorage.getItem('maxAutoPlayGames');
    }
  }, [])

  return (
    <div
      style={{
        display: props.gameRulesVisibility === true ? "block" : "none",
      }}
      className="game-rules-holder"
    >
      <FadeIn style={{}} delay={20}>
        <div className="container">
          <div className="game-rules-body">
            <div className="game-rules-header">
              <h1 className="game-rules-title">{label.t("general_rules")}</h1>
              <GetBackButton
                onClick={() => {
                  props.setGameRulesVisibility(false);
                }}
                label={label.t("get_back")}
              />
            </div>

            <p className="mb-4">{label.t("general_rules_text")}</p>

            <div className="general-rules-images row mb-4">
              <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                <div className="general-rules-box">
                  <h5>{label.t("select_numbers")}</h5>
                  <img src={menuIcons.gameRules1} alt="gamerules-1" />
                  <p>{label.t("select_numbers_text")}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                <div className="general-rules-box">
                  <h5>{label.t("select_betsize")}</h5>
                  <img src={
                    paramCurrency === 'fc' ? menuIcons.gameRules2fc :
                    paramCurrency === 'gc' ? menuIcons.gameRules2gc :
                    paramCurrency === 'sc' ? menuIcons.gameRules2sc :
                    paramLang === 'xe' ? menuIcons.gameRules2xe : 
                    menuIcons.gameRules2
                  } alt="gamerules-2" />
                  <p>{label.t("select_betsize_text")}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                <div className="general-rules-box">
                  <h5>{label.t("place_bet_title")}</h5>
                  <img src={
                    paramCurrency === 'fc' ? menuIcons.gameRules3fc :
                    paramCurrency === 'gc' ? menuIcons.gameRules3gc :
                    paramCurrency === 'sc' ? menuIcons.gameRules3sc :
                    paramLang === 'xe' ? menuIcons.gameRules3xe : 
                    menuIcons.gameRules3
                    } alt="gamerules-3" />
                  <p>{label.t("place_bet_text")}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="general-rules-box">
                  <h5>{label.t("watch_gamedraw")}</h5>
                  <img src={menuIcons.gameRules4} alt="gamerules-4" />
                  <p>{label.t("watch_gamedraw_text")}</p>
                </div>
              </div>
            </div>

            <h1 className="game-rules-title">{label.t("paytable")}</h1>
            <p className="mb-4">{label.t("paytable_text")}</p>
            <div className="game-rules-table">
              <table>
                <thead>
                  <tr>
                    <th>{label.t("position")}</th>
                    <th>{label.t("multiplier_value")}</th>
                  </tr>
                </thead>
                <tbody>
                  {oddHolder.slice(5, 20).map((ball, index) => {
                    return (
                      <tr key={index}>
                        <td>{ball.position}</td>
                        <td>{ball.odd}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>{label.t("position")}</th>
                    <th>{label.t("multiplier_value")}</th>
                  </tr>
                </thead>
                <tbody>
                  {oddHolder.slice(20, oddHolder.length).map((ball, index) => {
                    return (
                      <tr key={index}>
                        <td>{ball.position}</td>
                        <td>{ball.odd}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <p>{label.t("formula_text")}</p>
            <p className="mb-4">
              <i>{label.t("formula_text2")}</i>
            </p>

            {(storage.get('hideRTP') !== true ?
              <> <h1 className="game-rules-title">{label.t("return_toplayer")}</h1>
                <p className="mb-4">
                  {label.t("return_to_player_text1")} {props.rtp}%{". "} <br />
                  {label.t("return_to_player_text2")} {props.rtp2} <br />
                  {label.t("return_to_player_text3")}
                </p> </> : null)}

            <h1 className="game-rules-title">{label.t("bonus_game")}</h1>
            <p className="mb-4">{label.t("bonus_game_text")}</p>
            {(storage.get('removeJackpotRules') == true ? 
            null
            : 
            <>
            <h1 className="game-rules-title">{label.t("progressive_jp")}</h1>
            <p>{label.t("progressive_jp_text1")}</p>
            <p>{label.t("progressive_jp_text3")}</p>
            <p>{label.t("progressive_jp_text4")}</p>
            <p className="mb-4">{label.t("progressive_jp_text5")}</p>
            <p>{label.t("progressive_jp_text6")}</p>
            </>)}

            {(storage.get('fastBallsAutoPlay') !== false ?
              <>
                <h1 className="game-rules-title">{label.t("autoplay")}</h1>
                <p className="mb-4">
                  {label.t("autoplay_text")}
                  <span id="AUTOPLAY_MAX"></span>&nbsp;
                  {label.t("autoplay_text2")}
                </p>
              </>
              : null)}

            <h1 className="game-rules-title">{label.t("chat")}</h1>
            <p>{label.t("chat_text1")}</p>
            <p className="mb-4">{label.t("chat_text2")}</p>

            <h1 className="game-rules-title">{label.t("sound")}</h1>
            <p className="mb-4">{label.t("sound_text")}</p>

            <h1 className="game-rules-title">{label.t("game_history")}</h1>
            <p className="mb-4">{label.t("game_history_text")}</p>

            <h1 className="game-rules-title">{label.t("bet_placement")}</h1>
            <p className="mb-4">{label.t("bet_placement_text")}</p>

            <h1 className="game-rules-title">{label.t("dis_policy")}</h1>
            <p className="mb-4">{label.t("dis_policy_text")}</p>

            <h1 className="game-rules-title">{label.t("err_handling")}</h1>
            <p className="mb-4">{label.t("err_handling_text")}</p>

            <p>{label.t("max_bet")} {formatNumber(parseFloat(storage.get("maxBetAmount")))}{" "}{storage.get("currencyCode")}</p>
            <p>{label.t("min_bet")} {formatNumber(parseFloat(storage.get("minBetAmount")))}{" "}{storage.get("currencyCode")}</p>
            <p>{label.t("max_payout")} {formatNumber(parseFloat(storage.get("maxWin")))}{" "}{storage.get("currencyCode")} {label.t("per_bet")}</p>
             
            <p className="red-text">
              {label.t("malfunction_voids_text")}{" "}
            </p>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}

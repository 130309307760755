export var toggleFullScreen = function() {
	var element = document.documentElement;
	if(document.fullscreenElement == null) {
		if(element.requestFullscreen) {
			element.requestFullscreen()
		} else if(element.mozRequestFullScreen) {
			element.mozRequestFullScreen()
		} else if(element.webkitRequestFullscreen) {
			element.webkitRequestFullscreen()
		} else if(element.msRequestFullscreen) {
			element.msRequestFullscreen()
		}
	} else {
		if(document.exitFullscreen) {
			document.exitFullscreen()
		} else if(document.webkitExitFullscreen) {
			document.webkitExitFullscreen()
		} else if(document.msExitFullscreen) {
			document.msExitFullscreen()
		}
	}
}
import flame from "../../assets/images/flame.png";
import handleNumber from "../../services/Helpers/HandleNumber";

export default function ResultsBallSmall(props) {
  
  return (
    <div className={"results-ball " + handleNumber(props.label)}>
      {props.label}
      {props.winBall ? (
        <div className="flame-ball-small">
          <img className="flame-ball-fire-small" src={flame} alt="flame-ball" />
        </div>
      ) : null}
    </div>
  );
}

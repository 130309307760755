
export default function calcComb (r, n) {
  function range(a,b) {
    var prd = a,i = a;
    while (i++< b) {
      prd*=i;
    }
    return prd;
  }
  if (n===r) {
    return 1;
  } else {
    r=(r < n-r) ? n-r : r;
    return range(r+1, n)/range(1,n-r);
  }
}

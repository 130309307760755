import loadingScreenBackground from "../../assets/images/loading-screen-background-low.webp";
import logo from "../../assets/images/logo.png";
import FadeIn from "react-fade-in";
import {toggleFullScreen} from "../../services/Helpers/fullscreen";
import PrimaryButton from "../Buttons/PrimaryButton";
import {useEffect} from "react";

export default function AskFS(props) {
  useEffect(() => {

    console.log('props.label.t("fullscreenAsk")', props.label.t("fullscreenAsk"))
  }, [props.label])


  return (
    <div className={"notify-screen"} style={{opacity: 0.95}} >
      <div className="loading-screen-background" style={{zIndex: -1}}>
        <img
          src={loadingScreenBackground}
          alt="loading-background"
        />
      </div>
      <FadeIn style={{zIndex: 10000000, padding: '12px', textAlign: 'center'}} delay={200}>
        <div style={{fontFamily: '"Kanit-Medium", Roboto-Medium', fontSize: '40px', paddingBottom: '20px', color: '#fff'}}>

          <h1 style={{textAlign: 'center'}}>{props.label.t("fullscreenAsk")}</h1>
          <div  style={{textAlign: 'center', margin: '15px 15px 15px 15px' }}>
            <div style={{display: 'flex', margin: '2% -22% -15% 22%' , width: '50%'}}>
              <PrimaryButton onClick={() => {
                toggleFullScreen()
                props.setNotificationAksFSVisibility(false)
                props.setNotificationFSClockVisibility(true)
              }}
                label={props.label.t('allow')}
                ></PrimaryButton>
                  <div style={{width: '20px'}}>
                  </div>
              <PrimaryButton onClick={() => {
                props.setNotificationAksFSVisibility(false)
              }}
                label={props.label.t('deny')} ></PrimaryButton>
            </div>
            
          </div>
          <div style={{marginTop: '20%', marginBottom: '0%', textAlign: 'center'}}>
              <img
                src={logo}
                width="250px"
                alt="loading-background"
              />
            </div>

        </div>
      </FadeIn>
    </div>
  );
}

import React from "react";
import logo from "../../assets/images/logo.svg";
import menu from "../../assets/images/menu.svg";
import HeaderBalance from "../Balance/Balance";
import FadeIn from "react-fade-in";
import label from "../../services/Translation/lang";
import Switch from "react-switch";
import ArrowRightSvg from "../svg/arrow-right-svg";
import HeaderAvatar from "../Avatar/Avatar";
import storage from "../../services/Storage/Storage";
import { useEffect } from "react";
import { isMobile } from "../../services/Helpers/checkPlatform";
import {urlParams} from "../../services/UrlParamsLoader/UrlParamsLoader";
import { useLocation, useNavigate } from "react-router-dom";

const menuIcons = {
  sound: "/menu-icons/sound-setting.svg",
  music: "/menu-icons/music-settings.svg",
  history: "/menu-icons/promo-history.svg",
  provablyFair: "/menu-icons/provably-fair.svg",
  gameRules: "/menu-icons/game-rules.svg",
  betHistory: "/menu-icons/bet-history.svg",
};


export default function Header(props) {
  const handleChangeMusic = (v) => {
    props.net.requestUpdateSettings(props.optionSound, v);
    props.setOptionMusic(v);
  };

  const handleChangeSound = (v) => {
    props.net.requestUpdateSettings(v, props.optionMusic);
    props.setOptionSound(v);
  };

  const handleMyBetsBonusingHistoryLink = () => {
    props.setMainMenuVisibility(!props.mainMenuVisibility);
    if (!isMobile() && document.getElementById("left-tabs-example-tab-first")) {
      document.getElementById("left-tabs-example-tab-first").click();
      document.getElementById("uncontrolled-tab-results-tab-myBets").click();
      document
        .getElementById("uncontrolled-tab-my-bets-tab-tab-myBounsingHistory")
        .click();
    } else if (isMobile() && document.getElementById("bottom-nav-results")) {
      document.getElementById("bottom-nav-results").click();
      document.getElementById("uncontrolled-tab-results-tab-myBets").click();
      document
        .getElementById("uncontrolled-tab-my-bets-tab-tab-myBounsingHistory")
        .click();
    }
  };

  const handleMyBetsHistoryLink = () => {
    props.setMainMenuVisibility(!props.mainMenuVisibility);
    if (!isMobile() && document.getElementById("left-tabs-example-tab-first")) {
      document.getElementById("left-tabs-example-tab-first").click();
      document.getElementById("uncontrolled-tab-results-tab-myBets").click();
      document
        .getElementById("uncontrolled-tab-my-bets-tab-tab-myBetsAll")
        .click();
    } else if (isMobile() && document.getElementById("bottom-nav-results")) {
      document.getElementById("bottom-nav-results").click();
      document.getElementById("uncontrolled-tab-results-tab-myBets").click();
      document
        .getElementById("uncontrolled-tab-my-bets-tab-tab-myBetsAll")
        .click();
    }
  };

  const location = useLocation();
const navigate = useNavigate();
  
const backUrl = urlParams.get("backUrl");

const handleButtonClick = () => {
  if (backUrl) {
    const absoluteBackUrl = backUrl.startsWith("http://") || backUrl.startsWith("https://")
      ? backUrl
      : `https://${backUrl}`;

    window.top.location.replace(absoluteBackUrl);
  } else {
    navigate("/");
  }
};

  useEffect(() => {
    // console.log('', props.checkMinBet)
  }, [props.checkMinBet]);

  return (
    <div className="header">
      <div className="container position-relative">
        <div>
          <img src={logo} alt="logo" />
        </div>
        <div className="balance-holder">
          <HeaderBalance
            net={props.net}
            balance={props.balance}
            isPromo={false}
          />
          {props.promoBalance >= props.checkMinBet ? (
            <div style={{ marginRight: "5px" }}>
              <HeaderBalance
                net={props.net}
                balance={props.promoBalance}
                isPromo={true}
              />
            </div>
          ) : null}
        </div>

        <HeaderAvatar
          net={props.net}
          showUsernameComp={props.showUsernameComp}
          setShowUsernameComp={props.setShowUsernameComp}
          isNicknameAdded={props.isNicknameAdded}
          setIsNicknameAdded={props.setIsNicknameAdded}
          avatarMenuVisibility={props.avatarMenuVisibility}
          setAvatarMenuVisibility={props.setAvatarMenuVisibility}
          setShowChangeAvatar={props.setShowChangeAvatar}
        />

        <div data-mainmenu="true">
          <button
            className="header-button menu-button"
            onClick={() => {
              props.setMainMenuVisibility(!props.mainMenuVisibility);
            }}
          >
            <img src={menu} alt="menu" />
            <div className="menu-button-indicator"></div>
          </button>
        </div>
        <div
          data-mainmenu="true"
          style={{
            display: props.mainMenuVisibility === true ? "block" : "none",
          }}
          className="main-menu-dropdown"
        >
          {props.mainMenuVisibility === true ? (
            <FadeIn
              style={{
                background: "#181d34",
                color: "red",
                zIndex: 10000000,
                padding: "12px",
                textAlign: "center",
              }}
              delay={200}
            >
              <div className="container menu-page">
                <div className="menu-item-switch-holder">
                  <img alt="Music" src={menuIcons.music} />
                  <p className="menu-item-title">{label.t("music_settings")}</p>
                  <Switch
                    onChange={handleChangeMusic}
                    checked={props.optionMusic}
                    className={
                      props.optionMusic
                        ? "move-left react-switch"
                        : "react-switch"
                    }
                    onColor="#202558"
                    offColor="#202558"
                    borderRadius={26}
                    width={100}
                    height={28}
                    handleDiameter={28}
                    activeBoxShadow="0px 0px 15px rgba(255, 191, 26, 0.8)"
                    uncheckedIcon={
                      <div className="switcher-on-inactive">
                        {label.t("on")}
                      </div>
                    }
                    checkedIcon={
                      <div className="switcher-off-inactive">
                        {label.t("off")}
                      </div>
                    }
                    uncheckedHandleIcon={
                      <div className="switcher-off">{label.t("off")}</div>
                    }
                    checkedHandleIcon={
                      <div className="switcher-on">{label.t("on")}</div>
                    }
                  />
                </div>

                <div className="menu-item-separator"> </div>

                <div className="menu-item-switch-holder">
                  <img alt="Audio" src={menuIcons.sound} />
                  <p className="menu-item-title">{label.t("audio_settings")}</p>
                  <Switch
                    onChange={handleChangeSound}
                    checked={props.optionSound}
                    className={
                      props.optionSound
                        ? "move-left react-switch"
                        : "react-switch"
                    }
                    onColor="#202558"
                    offColor="#202558"
                    borderRadius={26}
                    width={100}
                    height={28}
                    handleDiameter={28}
                    activeBoxShadow="0px 0px 15px rgba(255, 191, 26, 0.8)"
                    uncheckedIcon={
                      <div className="switcher-on-inactive">
                        {label.t("on")}
                      </div>
                    }
                    checkedIcon={
                      <div className="switcher-off-inactive">
                        {label.t("off")}
                      </div>
                    }
                    uncheckedHandleIcon={
                      <div className="switcher-off">{label.t("off")}</div>
                    }
                    checkedHandleIcon={
                      <div className="switcher-on">{label.t("on")}</div>
                    }
                  />
                </div>

                <div className="menu-item-separator"> </div>

                <div className="menu-item-switch-holder">
                  <img alt="gr" src={menuIcons.gameRules} />
                  <p
                    onClick={() => {
                      //
                      props.setGameRulesVisibility(!props.gameRulesVisibility);
                      props.setMainMenuVisibility(false);
                    }}
                    className="menu-item-title"
                  >
                    {label.t("game_roles")}
                  </p>
                  <ArrowRightSvg />
                </div>

                <div className="menu-item-separator"> </div>

                <div className="menu-item-switch-holder">
                  <img width="27px" alt="gr" src={menuIcons.history} />
                  <p
                    onClick={() => handleMyBetsHistoryLink()}
                    className="menu-item-title"
                  >
                    {label.t("my_bet_history")}
                  </p>
                  <ArrowRightSvg />
                </div>

                <div className="menu-item-separator"> </div>

                <div className="menu-item-switch-holder">
                  <img
                    style={{ padding: "3px" }}
                    alt="gr"
                    src={menuIcons.betHistory}
                  />
                  <p
                    onClick={() => {
                      handleMyBetsBonusingHistoryLink();
                    }}
                    className="menu-item-title"
                  >
                    {label.t("bonus_history")}
                  </p>
                  <ArrowRightSvg />
                </div>

                 <div className="menu-item-switch-holder">
                  {backUrl != null ? (
                    <button className="get-back-button" onClick={handleButtonClick}>
                    {label.t("back_to_home")}
                  </button>
                  ) : null}
                  </div>   
              </div>
            </FadeIn>
          ) : null}
        </div>
      </div>
    </div>
  );
}

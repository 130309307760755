import * as React from "react";

const ChooseAmountSvg = (props) => (
  <svg
    width={23}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.33 9.5h2.17A4.5 4.5 0 0 1 15 14H7.999L8 15h8v-1a5.579 5.579 0 0 0-.886-3H18a5 5 0 0 1 4.516 2.851C20.151 16.972 16.322 19 12 19c-2.761 0-5.1-.59-7-1.625V8.071A6.967 6.967 0 0 1 8.33 9.5ZM4 17a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v9ZM17 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm-7-3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
      fill="#1070FF"
    />
  </svg>
);

export default ChooseAmountSvg;

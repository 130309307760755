import { storageEnabled } from "../StorageEnabled";
import LocalStorage from "./LocalStorage";
import MemoryStorage from "./MemoryStorage";

class Storage  {
  constructor(clientStorage) {
    this.clientStorage = clientStorage;
  }

  set(key,valueToStore) {
    try {
      this.clientStorage.set(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  }

  get(key) {
    try {
      return this.clientStorage.get(key) ? JSON.parse(this.clientStorage.get(key)) : null;
    } catch (error) {
      console.log(error);
    }
  }

  remove(key) {
    try {
      this.clientStorage.remove(key);
    } catch (error) {
      console.log(error);
    }
  }

}

const storage = new Storage(storageEnabled() ? new LocalStorage() : new MemoryStorage());
export default storage;

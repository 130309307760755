
/**
 * MemoryStorage
 */
 class MemoryStorage {

  constructor() {
    this.memorySet = {};
  }

  set(key,valueToStore) {
    try {
      this.memorySet[key] =  JSON.stringify(valueToStore);
    } catch (error) {
      console.log("There was an error storing " + key + "in memory storage :" + error);
    }
  }

  get(key) {
    try {
      const item = this.memorySet[key];
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.log("There was an getting key " + key + "from memory storage :" + error);
      return null;
    }
  }

  remove(key) {
    try {
      delete this.memorySet[key];
    } catch (error) {
      console.log("There was an getting key " + key + "from memory storage :" + error);
    }
  }

}

export default MemoryStorage;

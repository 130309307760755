function handleNumber(label) {
  switch (label % 8) {
    case 1:
      return "red-ball";
    case 2:
      return "green-ball";
    case 3:
      return "blue-ball";
    case 4:
      return "purple-ball";
    case 5:
      return "brown-ball";
    case 6:
      return "yellow-ball";
    case 7:
      return "orange-ball";
    case 0:
      return "black-ball";
    default:
      return "default-ball";
  }
}
export default handleNumber;

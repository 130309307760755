
const urlParams = new URLSearchParams(window.location.search);

export function isMobile() {
  const version = urlParams.get("version");
  return (version === "mobile" || window.innerWidth < 1024);
}

export function isExtraSmallResolution() {
  return window.innerWidth < 576 && window.innerHeight < 750;
}

export function isSafari() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1) { 
    if (ua.indexOf('chrome') > -1) {
      return 'Chrome'; // Chrome
    } else {
      return 'Safari'; // Safari
    }
  } else {
    return false;
  }
}

import React, {useEffect, useState} from "react";
import label from "../../services/Translation/lang";
import usernameConfig from "../../assets/config/username-config.json"
import FadeIn from "react-fade-in";
import {getAvatar} from "../../model/avatars";
import storage from "../../services/Storage/Storage";

const HeaderAvatar = (props) => {
	const [nick, setNick] = useState("");
	const [isVisibleNick, setIsVisibleNick] = useState(true);
	let testUsername = localStorage.getItem('username');
	let whatUsernameIs = storage.get("serverUsername");

	function checkPreventChangeNickname() {
		const platformIds = usernameConfig.platformIds;
		console.log("platformIds [allowed list for change nick] ->", platformIds);
		if(platformIds.indexOf(parseInt(sessionStorage.getItem('platform_id'))) !== -1) {
			setIsVisibleNick(true)
		} else {
			setIsVisibleNick(false)
		}
	}

	useEffect(() => {
		checkPreventChangeNickname()
		if(testUsername === 'empty') {
			setNick(whatUsernameIs);
		} else {
			setNick(testUsername);
		}
	}, [props.showUsernameComp, props.net.avatarId, testUsername, whatUsernameIs]);
	return (
		<div className="avatar-dropdown-holder">
			<div
				style={{cursor: "pointer", position: "relative"}}
				className="header-credit text-white"
				onClick={() => props.setAvatarMenuVisibility(true)}
			>
				<span className="avatar-menu desktop">
					{label.t("hey")}&nbsp;{nick}&nbsp;
				</span>
				<img
					style={{marginLeft: 4}}
					width="22px"
					height="22px"
					src={getAvatar(
						typeof props.net.avatarId !== "undefined" ? props.net.avatarId : 0
					)}
					alt={"slow internet"}
				/>
			</div>
			<div
				data-avatarmenu="true"
				style={{
					display: props.avatarMenuVisibility === true ? "block" : "none",
				}}
				className="avatar-dropdown"
			>
				{props.avatarMenuVisibility === true ? (
					<FadeIn
						style={{
							background: "#181d34",
							color: "red",
							zIndex: 10000000,
							padding: "12px",
							textAlign: "center",
						}}
						delay={200}
					>
						<span className="avatar-menu mobile">
							{label.t("hey")}&nbsp;{nick}&nbsp;
						</span>
						<div className="container menu-page">
							<div className="menu-item-switch-holder">
								<p
									onClick={() => {
										// console.log("show change_avatar !")
										props.setShowChangeAvatar(true);
										props.setAvatarMenuVisibility(false);
									}}
									className="menu-item-title"
								>
									{label.t("change_avatar")}
								</p>
							</div>
							<div className="menu-item-separator"> </div>
							{
								isVisibleNick === true ?
									<div className="menu-item-switch-holder">
										<p
											onClick={() => {
												// console.log("show change_username !")
												props.setShowUsernameComp(true);
												props.setAvatarMenuVisibility(false);
											}}
											className="menu-item-title"
										>
											{label.t("change_username")}
										</p>
									</div>
									:
									<></>
							}

						</div>
					</FadeIn>
				) : null}
			</div>
		</div>
	);
};

export default HeaderAvatar;

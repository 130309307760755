/**
 * @description
 * Avatars staff - active used in app.
 */

const getAvatar = (id) => {
  return "/avatars/Avatar_" + id + ".svg"
}

let avatars = {
  sources: []
};

for (let j=1; j < 51;j++) {
  avatars.sources.push(getAvatar(j));
}

export {
  getAvatar,
  avatars
}

import ActiveTicketSvg from "../svg/active-ticket-svg";
import YourTicketBall from "./YourTicketBall";
import calcComb from "../../services/Helpers/CalculateNumOfComb";
import {useEffect, useMemo, useState} from "react";
import label from "../../services/Translation/lang";
import storage from "../../services/Storage/Storage";
import AutoPlayBagde from "./AutoPlayBadge";
import PrimaryButton from "../Buttons/PrimaryButton";
import {currencyFormatter} from "../../services/Helpers/NumberFormatter";
import soundEffects from "../../services/Sounds/SoundFxPlayer";
import {isMobile} from "../../services/Helpers/checkPlatform";
// import {toast} from "react-toastify";

export default function ActiveTicket(props) {
	const [ticketWin, setTicketWin] = useState(0);
	const [lowestToHighest, setLowestToHighest] = useState([]);
	const [winHandler, setWinHandler] = useState([]);
	const numOfComb = calcComb(6, props.activeTicketNumbers.length);

	let truncateDecimals = function(number, digits) {

		// var digits = parseInt(sessionStorage.getItem('DECIMALS'));

		var multiplier = Math.pow(10, digits),
			adjustedNum = number * multiplier,
			truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
		return truncatedNum / multiplier;
	};

	const showCancelBtn = () => {
		if(props.betStatus === 1 && props.promoBalance < parseFloat(storage.get('minBetAmount')) && storage.get('autoplay') !== true) {
			if(props.gameStatus === 0 && storage.get('betAckTicketData') !== null &&
				JSON.parse(storage.get('betAckTicketData')).roundId === props.roundID) {
				return true;
			}
			if(props.gameStatus === 1 && storage.get('betAckTicketData') !== null &&
				JSON.parse(storage.get('betAckTicketData')).roundId !== storage.get('roundId')) {
				return true;
			}
		}
		return false;
	}

	useEffect(() => {
		setWinHandler([]);
		lowestToHighest.forEach((ball, index) => {
			// calculation of win also for mybet
			if(lowestToHighest.length > 5) {
				if(JSON.parse(storage.get('roundId')) === JSON.parse(storage.get('betAckTicketData'))?.roundId &&
					JSON.parse(storage.get('betAckTicketData')).roundId === props.roundID &&
					props.indexArray[props.indexArray.indexOf(ball)] === ball) {
					if(typeof props.indexArrayQuota[props.indexArray.indexOf(ball)] !== 'undefined') {
						setWinHandler((prevState) => {
							let t = [...prevState]
							t.push({
								winBall: ball,
								indexBall: props.indexArray.indexOf(ball),
								quota: props.indexArray.indexOf(ball) === 34 ? 1 : props.indexArrayQuota[props.indexArray.indexOf(ball)]
							});
							return [...t]
						})
					}
				}
			}
		});

	}, [ticketWin, props.indexArray, lowestToHighest, props.roundID, props.indexArrayQuota, props.activeTicketCurrentBet, props.currentBetAmount])



	useEffect(() => {
		if(winHandler.length >= 6) {
			const numOfSumComb = calcComb(6, lowestToHighest.length);
			let bet = (props.activeTicketCurrentBet !== 0 ?
				props.activeTicketCurrentBet : props.currentBetAmount);
			let stakePerCombination = bet / numOfSumComb;
			// let testWIN = 0;
			// let stakePerCombinationTEST = bet / numOfSumComb;
			//    sessionStorage.setItem('DECIMALS', this.noOfDecimals);
			stakePerCombination = truncateDecimals(stakePerCombination, 12);
			// console.log('-stakePerCombination : ', stakePerCombination)
			// stakePerCombination = parseFloat(stakePerCombination.toFixed(4));
			winHandler.sort(function(a, b) {
				return parseFloat(a.indexBall) - parseFloat(b.indexBall);
			});
			let ticketWinSum = 0;
			let localHandlerOfWinCombination = {
				lastState: 0,
				finalState: 0
			};
			winHandler.forEach((item, index) => {
				if(index >= 5) {
					localHandlerOfWinCombination.finalState = calcComb(6, (index + 1)) - localHandlerOfWinCombination.lastState;
					ticketWinSum += stakePerCombination * localHandlerOfWinCombination.finalState * winHandler[index].quota;
					ticketWinSum = parseFloat(ticketWinSum.toFixed(4));
					// console.log( item , ' <- item  AFTER fixed(4) , ticketWinSum : ', ticketWinSum)
					localHandlerOfWinCombination.lastState = calcComb(6, (index + 1));
				}
			})
			ticketWinSum = parseFloat(ticketWinSum.toFixed(2));
			// console.log('FINAL AFTER fixed(2) , ticketWinSum : ', ticketWinSum)
			storage.set('avoid', false);
			let optiActiveTicketWinEvent = new CustomEvent('optActiveTicket', {
				detail: {myTicketSum: ticketWinSum}
			});
			dispatchEvent(optiActiveTicketWinEvent);

			if(ticketWinSum > parseFloat(storage.get('maxWin'))) {
				setTicketWin(parseFloat(storage.get('maxWin')));
				// console.log('ACTIVE TICKET WIN CHECK MAX!' + ticketWinSum + " vs " + parseFloat(storage.get('maxWin')))
			} else {
				setTicketWin(ticketWinSum);
			}

			if(storage.get('atSoundPlayed') === false) {
				soundEffects.play('activeticket_win');
				storage.set('atSoundPlayed', true);
			}

		} else {
			if(props.indexArray.length !== 35) {
				setTicketWin(0);
			}
		}

	}, [ticketWin, props.indexArray, lowestToHighest, props.roundID, props.indexArrayQuota, props.activeTicketCurrentBet, props.currentBetAmount, winHandler])

	useEffect(() => {
		// autoplay
		let test = storage.get('autoPlayCurrentRound');
		if(props.autoPlayRounds === Infinity) {
			console.log("AUTOPLAY INFINITY");
		} else if(props.autoPlayRounds <= (test - 1)) {
			// in case for autoplay after refresh put props.autoPlayRounds in storage initial load.
			storage.set('autoplay', false);
			props.setAutoPlay(false);
			props.setAutoPlayRounds(0);
		}
		setLowestToHighest(props.activeTicketNumbers.sort((a, b) => a - b));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numOfComb, props.autoPlayRounds, props.activeTicketNumbers, props.currentBetAmount]);

	return (
		<div style={
			props.hidden === true ? {display: 'none'} :
				{display: props?.tab?.visible}
		} className={winHandler.length >= 6 ? "ticket-holder active-ticket-holder winner" : "ticket-holder active-ticket-holder"} >
			<div className="game-header active-ticket-game-header">
				<div className="d-flex">
					<ActiveTicketSvg className="live-game-icon" />
					{label.t("active_ticket")} # {(props.betAckTicketData.roundId !== undefined ? props.betAckTicketData.roundId : JSON.parse(storage.get('betAckTicketData'))?.roundId)}
				</div>

				{useMemo(() =>
					props.autoPlayRounds > 0 ?
						(<AutoPlayBagde
							roundID={props.roundID}
							autoPlayRounds={props.autoPlayRounds}
							autoPlayUpdateMe={props.autoPlayUpdateMe} />)
						: null, [props.autoPlayRounds, props.roundID, props.autoPlayUpdateMe])}

			</div>
			<div className="ticket-container">
				<div className="ticket-holder-body">
					<div className="ticket-holder-body-wrapper">
						{lowestToHighest.map((ball, index) => {
							return (
								<YourTicketBall
									key={index}
									label={ball}
									winningBall={
										JSON.parse(storage.get('roundId')) === JSON.parse(storage.get('betAckTicketData'))?.roundId &&
											props.roundID === JSON.parse(storage.get('betAckTicketData'))?.roundId &&
											props.indexArray[props.indexArray.indexOf(ball)] === ball ? true : false
									}
								/>
							)
						})}
					</div>
				</div>
				<div className="active-ticket-footer">
					<div className="active-ticket-footer-top">
						<div className="active-ticket-footer-label">
							{label.t("combinations")}: <span>{numOfComb}</span>
						</div>
						<div className="active-ticket-footer-label">
							{label.t("stake")}: <span>{(props.activeTicketCurrentBet !== 0 ?
							(sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(props.activeTicketCurrentBet, '###,###') : props.activeTicketCurrentBet) :
							(sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(props.currentBetAmount, '###,###') : props.currentBetAmount)
								// props.activeTicketCurrentBet : 
								// props.currentBetAmount)
								)} {props.userCurrencyCode}</span>
						</div>
					</div>
					<div className="active-ticket-footer-bottom">
						<div className="active-ticket-footer-label">
							{label.t("total_win")}: <span>{
								sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(ticketWin, '###,###') :
								currencyFormatter(ticketWin)
							} {props.userCurrencyCode}</span>
						</div>
					</div>
				</div>

				{useMemo(() => showCancelBtn() === true && isMobile() === true ?
					<div style={{width: '100%', marginTop: 8}}>
						<PrimaryButton
							onClick={() => {
								// console.log('CANCEL BET YES')
								props.net.cancelBet(JSON.parse(storage.get('betAckTicketData')).ticketId);
							}}
							label={label.t("cancel")}
							disabled={false}
						// eslint-disable-next-line react-hooks/exhaustive-deps
						/> </div> : null, [props.width, props.net, showCancelBtn])}
			</div>
		</div>
	);
}

import { Accordion } from "react-bootstrap";
import BetRoundBox from "./BetRoundBox";
import FadeIn from "react-fade-in";
import moment from 'moment';

export default function BetRounds(props) {
  if (typeof props === 'undefined') return null;
  return (
    <div className="results-tab">
      <Accordion className="bet-rounds" defaultActiveKey={0}>
        <FadeIn>
          {props.historyData.map((round, index) => {
            return (
              <BetRoundBox
                key={index}
                eventKey={index}
                roundId={round.roundId}
                date={round.date}
                time={moment.utc(`${round.date} ${round.time}`, 'YYYY-MM-DD HH:mm:ss').local().format('HH:mm:ss')}
                balls={round.numbers}
              />
            );
          })}
        </FadeIn>
      </Accordion>
    </div>
  );
}

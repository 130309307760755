import React, {useEffect} from "react";
import ReactTooltip from "react-tooltip";
import {currencyFormatter, formatNumber4} from "../../services/Helpers/NumberFormatter";
import storage from "../../services/Storage/Storage";
import label from "../../services/Translation/lang";
import CreditSvg from "../svg/credit-svg";
import CreditPromoSvg from "../svg/credit-promo-svg";

const HeaderBalance = (props) => {

  let testCC = storage.get('currencyCode');
  useEffect(() => {
    // console.log('HeaderBalance testCC => ', testCC);
   }, [testCC, props])

  return (
    <div data-tip={ props.isPromo === false ? label.t("balance"): label.t("promo_balance")} className="header-credit text-white">
      { props.isPromo === false ?
      sessionStorage.getItem('hideBalanceIcon') === 'true' ?
      <></> :
      <CreditSvg className="cursor-pointer" onClick={() => {
         props.net.requestUserBalance()
      }}  style={{ marginRight: '3px'}} /> :
      <CreditPromoSvg className="cursor-pointer" onClick={() => {
        props.net.requestUserBalance()
      }}  style={{ marginRight: '3px'}} /> }
      {(sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(props.balance, "###,###"): currencyFormatter(props.balance))}
       <span className="header-credit-currency" > {storage.get('currencyCode')} </span>
      <ReactTooltip
        className="bet-tooltip"
        place="left"
        effect="solid"
        backgroundColor="#ffbf19"
      />
    </div>
  );
};
export default HeaderBalance;

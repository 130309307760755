import React from "react";
import Flag from "react-world-flags";
import { getAvatar } from "../../model/avatars";
import {currencyFormatter} from "../../services/Helpers/NumberFormatter";

const JackpotTableTr = (props) => {
  return (
    <tr className="all-bets-tr">
      <td>{props.date}</td>
      <td>{props.time}</td>
      <td>{ sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(props.jackpotWinAmount, '###,###') : props.jackpotWinAmount}</td>
      <td>
        <div className="all-bets-td-holder">
          <div className="all-bets-tr-avatar">
            <img src={getAvatar(props.avatar)} alt="" />
          </div>
          <span className="all-bets-tr-username">{props.username}</span>
          <div className="all-bets-tr-flag">
            {props.flagCode === "XS" ? (
              <Flag className="xs-flag" code={"RU"} height="10" width="14" />
            ) : (
              <Flag code={props.flagCode} height="10" width="14" />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default JackpotTableTr;
import {useEffect, useState} from "react";
import storage from "../../services/Storage/Storage";
import label from "../../services/Translation/lang"

export default function AutoPlayBagde(props) {
  // eslint-disable-next-line no-unused-vars
  const [autoPlayCurrentRound, setAutoPlayCurrentRound] = useState(0);
  useEffect(() => {
    setAutoPlayCurrentRound(storage.get('autoPlayCurrentRound'))
  }, [props.roundID, props.autoPlayUpdateMe]);

  return (
    <div className="your-ticket-clear">
      <span className="autoplay-badge-number">{storage.get('autoPlayCurrentRound')}</span>/
      {props.autoPlayRounds === Infinity ? "∞" : props.autoPlayRounds}{" "}
      {label.t("autoplay_lc")}
    </div>
  )
}
import React from "react";
import classNames from "classnames";
import Odometer from "react-odometerjs";

const StatsbarHOC = props => (
  <span className={props.odometerStyle}>
    <Odometer
      animation={props.animation}
      duration={props.duration}
      format={props.format}
      theme={props.theme}
      value={ props.value.toString().split('.').length <= 1 ||
             (props.value.toString().split('.').length > 1 &&
              props.value.toString().split('.')[1].length < 2) ? props.value + 0.01 : props.value }
    />
  </span>
);

class Statsbar extends React.Component {
  render() {
    const odometerClassname = classNames({
      "odometer-theme-default statNumberStyle": true
    });
    return (
      <span>
        <StatsbarHOC
          format={this.props.format}
          duration={this.props.duration}
          value={ this.props.value}
          animation={this.props.animation}
          theme={this.props.theme}
          odometerStyle={odometerClassname}
        />
      </span>
    );
  }
}

export default Statsbar;
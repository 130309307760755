import TrashCanSvg from "../svg/trash-can-svg";
import YourTicketSvg from "../svg/your-ticket-svg";
import YourTicketBall from "./YourTicketBall";
import calcComb from "../../services/Helpers/CalculateNumOfComb";
import { useEffect, useState } from "react";
import label from "../../services/Translation/lang";
import soundEffect from "../../services/Sounds/SoundFxPlayer";
import storage from "../../services/Storage/Storage";
import {currencyFormatter, formatNumber4} from "../../services/Helpers/NumberFormatter";
import {urlParams} from "../../services/UrlParamsLoader/UrlParamsLoader";

export default function YourTicket(props) {
  const [maxWinValue, setMaxWinValue] = useState(0);

  let numOfComb;
  if (props.ticketNumbers.length < 6) {
    numOfComb = 0;
  } else {
    numOfComb = calcComb(6, props.ticketNumbers.length);
  }

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  useEffect(() => {
    if (numOfComb > 0) {
      let stakePerCombination = props.currentBetAmount / numOfComb;
      // validation for bet_amount
      if(props.ticketNumbers.length > 6) {
        // start context combination min bet amount calculation
        const numOfComb = calcComb(6, props.ticketNumbers.length);
        /**
         * @formula
         * if MinBetCombination * NrCombinations < MinBet: 
         *  Bet = MinBet
         * else:
         *  Bet = MinBetCombination * NrCombinations 
         */
        // let newBetAmountByMinBet = parseFloat((numOfComb * props.minBetAmount).toFixed(4));
        let newBetAmountByMinPC = parseFloat((numOfComb * props.minBetPerComb).toFixed(4));
        if (newBetAmountByMinPC > props.currentBetAmount && newBetAmountByMinPC >= props.minBetAmount) {
					if(urlParams.get("crypto") === "true" || urlParams.get("crypto") === true) {
						props.setCurrentBetAmount(parseFloat(newBetAmountByMinPC));
						storage.set('currentBetAmount', parseFloat(newBetAmountByMinPC))
					} else {
						props.setCurrentBetAmount(parseFloat(newBetAmountByMinPC).toFixed(props.net.noOfDecimals));
						storage.set('currentBetAmount', parseFloat(newBetAmountByMinPC).toFixed(props.net.noOfDecimals));
					}
          // console.log('SET [ pass 1] = ', newBetAmountByMinPC);
        } else if (newBetAmountByMinPC < props.minBetAmount) {
          // it is less but maybe no less on currentBet check it
          // Proirity is props.currentBetAmount whay user setup!
          if (props.currentBetAmount > props.minBetAmount) {
             // do nothing
             if (storage.get('dontCheckBackToMin') !== true) {
              // console.log(' DISABLED [ dontCheckBackToMin != true ] = ', newBetAmountByMinPC);
              // props.setCurrentBetAmount(props.minBetAmount);
              // storage.set('currentBetAmount', props.minBetAmount);
              // console.log('SET [ < minBet ] = ', props.minBetAmount);
             } else {
               // console.log('NOTHING [ dontCheckBackToMin = true ] = ', newBetAmountByMinPC);
             }
          } else {
            // Set min bet.
            // DISABLED
            // props.setCurrentBetAmount(props.minBetAmount);
            // storage.set('currentBetAmount', props.minBetAmount);
            // console.log('DISABLED SET [ < minBet ] = ', props.minBetAmount);
          }
        } else {
          if (storage.get('dontCheckBackToMin') !== true) {
            // check and back to min
            // console.log('SET2 [ setCurrentBetAmount ] = ', newBetAmountByMinPC);
            // props.setCurrentBetAmount(newBetAmountByMinPC);
            // storage.set('currentBetAmount', newBetAmountByMinPC);
						if(urlParams.get("crypto") === "true" || urlParams.get("crypto") === true) {
							props.setCurrentBetAmount(parseFloat(newBetAmountByMinPC));
							storage.set('currentBetAmount', parseFloat(newBetAmountByMinPC))
						} else {
							props.setCurrentBetAmount(parseFloat(newBetAmountByMinPC).toFixed(props.net.noOfDecimals));
							storage.set('currentBetAmount', parseFloat(newBetAmountByMinPC).toFixed(props.net.noOfDecimals));
						}
          }
        }
        // end of context
      } else if (props.ticketNumbers.length <= 6) {
        if (storage.get('dontCheckBackToMin') !== true) {
          // console.log('SET3 [ (dontCheckBackToMin) !== true] = ');
          // props.setCurrentBetAmount(parseFloat(storage.get('minBetAmount')));
					if(urlParams.get("crypto") === "true" || urlParams.get("crypto") === true) {
						props.setCurrentBetAmount(props.minBetAmount);
						storage.set('currentBetAmount', parseFloat(props.minBetAmount))
					} else {
						props.setCurrentBetAmount(parseFloat(props.minBetAmount).toFixed(props.net.noOfDecimals));
						storage.set('currentBetAmount', parseFloat(props.minBetAmount).toFixed(props.net.noOfDecimals));
					}
        }
      }

      if (numOfComb === 1) {
        let t = parseFloat(stakePerCombination * 10000);
        if (t > props.maxWin) {
          setMaxWinValue(props.maxWin);
        } else {
          setMaxWinValue(t.toFixed(2));
        }
      } else if (numOfComb === 7) {
        let t = (parseFloat(stakePerCombination * 10000) +
          parseFloat((stakePerCombination * 7500) * (numOfComb-1)));
        if (t > props.maxWin) {
          setMaxWinValue(props.maxWin);
        } else {
          setMaxWinValue(t.toFixed(2));
        }
      } else if (numOfComb === 28) {  
        let t = parseFloat(stakePerCombination * 10000) +
          parseFloat((stakePerCombination * 7500) * ( 6 )) +
          parseFloat((stakePerCombination * 5000) * (numOfComb-7));
        if (t > props.maxWin) {
          setMaxWinValue(props.maxWin);
        } else {
          setMaxWinValue(t.toFixed(2));
        }
      } else if (numOfComb === 84) {
        let t = parseFloat(stakePerCombination * 10000) +
          parseFloat((stakePerCombination * 7500) * (6)) + 
          parseFloat((stakePerCombination * 5000) * (21)) +
          parseFloat((stakePerCombination * 2500) * (numOfComb-28)) 
        if (t > props.maxWin) {
          setMaxWinValue(props.maxWin);
        } else {
          setMaxWinValue(t.toFixed(2));
        }
      } else if (numOfComb === 210) {
        let t = parseFloat(stakePerCombination * 10000) +
          parseFloat((stakePerCombination * 7500) * 6) + 
          parseFloat((stakePerCombination * 5000) * 21) +
          parseFloat((stakePerCombination * 2500) * 56) +
          parseFloat((stakePerCombination * 1000) * (numOfComb-84));
        if (t > props.maxWin) {
          setMaxWinValue(props.maxWin);
        } else {
          setMaxWinValue(t.toFixed(2));
        }
      } else if (numOfComb === 462) {
        let t = parseFloat(stakePerCombination * 10000) +
          parseFloat((stakePerCombination * 7500) * (6)) +
          parseFloat((stakePerCombination * 5000) * (21)) +
          parseFloat((stakePerCombination * 2500) * (56)) +
          parseFloat((stakePerCombination * 1000) * (126)) +
          parseFloat((stakePerCombination * 500) * (numOfComb-210));
        if (t > props.maxWin) {
          setMaxWinValue(props.maxWin);
        } else {
          setMaxWinValue(t.toFixed(2));
        }
      } else if (numOfComb === 924) {

        let t = parseFloat(stakePerCombination * 10000) +
        parseFloat((stakePerCombination * 7500) * (6)) +
        parseFloat((stakePerCombination * 5000) * (21)) +
        parseFloat((stakePerCombination * 2500) * (56)) +
        parseFloat((stakePerCombination * 1000) * (126)) +
        parseFloat((stakePerCombination * 500) * (252)) + 
        parseFloat((stakePerCombination * 300) * (numOfComb-462))
        if (t > props.maxWin) {
          setMaxWinValue(props.maxWin);
        } else {
          setMaxWinValue(t.toFixed(2));
        }
      }
    } else {
      setMaxWinValue(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numOfComb, props.currentBetAmount, props.ticketNumbers, setMaxWinValue]);

  return (
    <div style={{ display: props?.tab?.visible }} className="ticket-holder">
      <div className="game-header">
        <div className="d-flex">
          <YourTicketSvg className="live-game-icon" />
          {label.t("your_ticket")}
        </div>
        <button
          className="your-ticket-clear"
          onClick={() => {
            let local = [];
            let someNum = randomIntFromInterval(1,48);
            while (local.length < 6) {
              someNum = randomIntFromInterval(1,48);
              if (local.indexOf(someNum) === -1) local.push(someNum);
            }
            props.setTicketNumbers([...local]);
            soundEffect.play('random6');
          }}
        >
          {label.t("random6")}
        </button>
        <button
          className="your-ticket-clear"
          onClick={() => {
            props.setTicketNumbers([]);
            soundEffect.play('ticket_clear');
            if(urlParams.get("crypto") === "true" || urlParams.get("crypto") === true) {
              props.setCurrentBetAmount(parseFloat(storage.get('initialCurrentBetAmount')));
              storage.set('currentBetAmount', parseFloat(props.minBetAmount).toFixed(props.net.noOfDecimals))
            } else {
              props.setCurrentBetAmount(parseFloat(props.minBetAmount).toFixed(props.net.noOfDecimals));
							storage.set('currentBetAmount', parseFloat(props.minBetAmount).toFixed(props.net.noOfDecimals));              
            }
          }}
        >
          {label.t("clear")} <TrashCanSvg className="your-ticket-clear-icon" />
        </button>
      </div>
      <div className="ticket-container">
        <div className="ticket-holder-body">
          <div className="ticket-holder-body-wrapper">
            {Array.from({ length: 12 }, (ball, index) => (
              <YourTicketBall label={props.ticketNumbers[index]} key={index} />
            ))}
          </div>
        </div>
        <div className="your-ticket-footer">
          <div className="active-ticket-footer-top">
            <div className="active-ticket-footer-label">
              {label.t("combinations")}: <span>{numOfComb}</span>
            </div>
            <div className="active-ticket-footer-label">
              {label.t("max_win")}: <span>
                {
                 sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(maxWinValue, '###,###') :
                 currencyFormatter(maxWinValue)
                }</span> {props.userCurrencyCode}
            </div>
            {props.autoPlayRounds > 0 ? (
              <div className="active-ticket-footer-label">
                <span className="text-capitalize">
                  {label.t("autoplay_lc")}:{" "}
                </span>
                {props.autoPlayRounds === Infinity ? "∞" : props.autoPlayRounds}{" "}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

import {useEffect, useState} from "react";
import Flag from "react-world-flags";
import FadeIn from "react-fade-in";
import storage from "../../services/Storage/Storage";

export default function UserMessage({...props}) {
  const [likeCount, setLikeCount] = useState(0);
  const [liked, setLiked] = useState(false);
  function handleLike() {
    props.net.requestChatLike(props.id);
    if(true) {
      const username = localStorage.getItem('username') !== "empty" ? localStorage.getItem('username').toString() : storage.get('serverUsername').toString();
      if(props.whoLikes.length > 0 && props.whoLikes.findIndex(item => item === username) !== -1) {
        setLikeCount(likeCount - 1);
        if(likeCount < 1) setLiked(!liked);
      } else {
        setLikeCount(likeCount + 1);
        if(likeCount < 1) setLiked(!liked);
      }
    }
  }

  useEffect(() => {
    setLikeCount(props.numberOfLikes);
  }, [props.numberOfLikes]);

  return (
    <FadeIn>
      <div
        className={
          "user-message-container " +
          (props.currentUserMessage ? "current-user-message-container " : " ") +
          (props.newMessage ? "new-message " : " ")
        }
      >
        <div className="user-message-wrapper">
          {props.gifMessage ? (
            <div className="gif-message">
              <img src={props.gifMessage} alt="gif" />
            </div>
          ) : (
            <div className="user-message">{props.label}</div>
          )}

          {likeCount > 0 ? (
            <div className="user-message-likes">
              {likeCount > 0 ? <span title={props.whoLikes.length > 0 ?
                props.whoLikes.map((item) => item) : null
              } >👍 {likeCount}</span> : null}
            </div>
          ) : null}
        </div>
        <div className="results-box-heder">
          <div className="results-user-avatar">
            <img src={props.img} alt={props.username} />
          </div>
          <div className="results-user-name">{props.username}</div>
          <div className="results-user-flag">
            {(storage.get('flagPreview') === false ? null : <Flag code={props.flag} height="10" width="14" />)}
          </div>
          <div className="message-time">{props.time}</div>
          <div className="message-buttons">
            <div onClick={() => handleLike()} className="message-button-like">
              👍
            </div>
          </div>
        </div>
      </div>
    </FadeIn>
  );
}

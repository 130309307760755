import * as React from "react";

const LiveGameSvg = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.414 4h5.594c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H.992A.996.996 0 0 1 0 19V5c0-.552.455-1 .992-1h5.594L4.05 1.464 5.464.05 9.414 4h1.172l3.95-3.95 1.414 1.414L13.414 4Z"
      fill="#1070FF"
    />
  </svg>
);

export default LiveGameSvg;

import handleNumber from "../../services/Helpers/HandleNumber";

export default function LotteryBall(props) {
  return (
    <div style={props.style} className={"lottery-drum-ball-holder " + (props.active ? "active" : "")}>
      <div className={"lottery-ball " + handleNumber(props.label)}>
        {props.label}
      </div>
    </div>
  );
}

import * as React from "react";

const BetsIconSvg = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 16.938V19h5v2H6v-2h5v-2.062A8 8 0 0 1 4 9V3h16v6a8 8 0 0 1-7 7.938ZM1 5h2v4H1V5Zm20 0h2v4h-2V5Z"
      fill="#fff"
      opacity={0.5}
    />
  </svg>
);

export default BetsIconSvg;

import {Accordion} from "react-bootstrap";
import FadeIn from "react-fade-in";
import storage from "../../services/Storage/Storage";
import label from "../../services/Translation/lang";
import {currencyFormatter} from "../../services/Helpers/NumberFormatter";

export default function MyBounsingHistory(props) {
  return (
    <div className="results-tab">
      <Accordion className="bet-rounds" defaultActiveKey={0}>
        <FadeIn childClassName="mbh-tr-color">
          <div className="my-bonusing-history-th">
            <div className="mbh-tr">{label.t('date')} & {label.t('time')}</div>
            <div className="mbh-tr">{label.t('bonus_type')}</div>
            <div className="mbh-tr">{label.t('win_amount')}</div>
          </div>
          { /** no exchange data 1 Just preview currency from server response for pBonusHistory */
            props.pBonusHistory ?
              props.pBonusHistory.map((item, index) => {
                return (
                  <div key={index} className="mbh-td">
                    <div className="mbh-tr">{item.date} {item.time} </div>
                    <div className="mbh-tr">{item.bonusType}</div>
                    <div className="mbh-tr">
                      {
                        (item.currencyCode !== props.userCurrencyCode ?
                          sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(Number(item.win), "###,###"):
                          currencyFormatter(Number(item.win * parseFloat(storage.get('currency_exchange_rate'))))
                          :
                          sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(Number(item.win), "###,###"):
                          currencyFormatter(Number(item.win)))}
                          &nbsp;
                      {
                       (item.currencyCode === props.userCurrencyCode ? item.currencyCode : item.currencyCode)
                      }
                    </div>
                  </div>
                )
              }) : null
          }
        </FadeIn>
      </Accordion>
    </div>
  );
}

import {useMemo} from "react";
import {currencyFormatter} from "../../services/Helpers/NumberFormatter";

export default function ChooseAmountButton(props) {
	return useMemo(
		() => (
			<button
				disabled={props.disabled}
				onClick={props.onClick}
				className="choose-amount-button"
			>
				{sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(props.label, "###,###") :
          currencyFormatter(props.label)}
				<span className="choose-amount-button-currency">{props.currency}</span>
			</button>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.currency, props.disabled, props.label]
	);
}

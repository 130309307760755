import { useEffect, useState } from "react";
import label from "../../services/Translation/lang";
import PrimaryButton from "../Buttons/PrimaryButton";
import loadingScreenBackground from "../../assets/images/loading-screen-background-low.webp";
import FadeIn from "react-fade-in";
import SecondaryButton from "../Buttons/SecondaryButton";
import { avatars } from "../../model/avatars";

export default function ChangeAvatar(props) {
  const [selectedAvatarId, setSelectedAvatarId] = useState(0);
  useEffect(()=> {
    setSelectedAvatarId(props.avatarId)
  }, [props.avatarId, props.net, props.net.avatarId]);
  useEffect(()=> {
    setSelectedAvatarId(selectedAvatarId)
  }, [selectedAvatarId]);
  return (
     <div style={props.style} className={"loading-screen-inapp"}>
      <div className="loading-screen-background" style={{zIndex: -1}}>
        <img
          src={loadingScreenBackground}
          alt="loading-background"
        />
      </div>
      <FadeIn className="change-avatar-fade-container" style={{ zIndex: 10000000, padding: '12px', textAlign: 'center'}} delay={200}>
         <div style={{
            fontFamily: '"Kanit-Medium", Roboto-Medium',
            paddingBottom: '20px',
            fontSize: '30px',
            color: '#fff'}}>
            {label.t('change_avatar')}</div>
         <div className="avatar-holder">
         {
          avatars.sources.map((a, index) => {
            // console.log("selectedAvatarId ", selectedAvatarId)
            return (
              <div className={( selectedAvatarId === index+1 ? "avatar-item avatar-selected" :"avatar-item")} 
                   onClick={(e)=>{
                    // console.log("E ", e.target.getAttribute("data-avatarid"))
                    let detAvatarId = parseFloat(e.target.getAttribute("data-avatarid")) - 1;
                    setSelectedAvatarId(detAvatarId + 1);
                   }}
                   key={index}>
                <img data-avatarid={index+1} src={a} alt="Slow internet"/>
              </div>)
          })}
          </div>
          <div style={{ width: '50%', margin: 'auto'}}>
            <PrimaryButton
              style={{ marginTop: '8px' }}
              small
              onClick={() => {
                // console.log("SEND SERVER AVATAR ID REQUEST +selectedAvatarId. ", selectedAvatarId)
                props.net.requestSetAvatarId(selectedAvatarId);
                setTimeout(() => {props.setShowChangeAvatar(false)}, 400)
              }}
              label={label.t("save")}/>
            <SecondaryButton
              style={{ marginTop: '8px' }}
              small
              onClick={() => {
                console.log("HiDE ME")
                props.setShowChangeAvatar(false);
              }}
              label={label.t("cancel")}/>
          </div>
      </FadeIn>
    </div>
  );
}

import handleNumber from "../../services/Helpers/HandleNumber";
import hotball from "../../assets/images/flame-big.png";

export default function YourTicketBall(props) {
  return (
    <div style={{ display: props?.tab?.visible }} className="your-ticket-ball-holder">
      {props.winningBall ? (
        <div className="flame-ball">
          <img src={hotball} alt="flame-ball" />
        </div>
      ) : null}
      <div className="your-ticket-ball-wrapper">
        <div
          className={
            "your-ticket-ball " +
            handleNumber(props.label) +
            (props.winningBall ? " winning-ball" : " ")
          }
        >
          {props.label ? (
            <div className="your-ticket-ball-border">{props.label}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
